import { createReducer } from '@reduxjs/toolkit'
import { calculate, getParametersOptions, resetCalculations } from './actions'

const initialState = {
  parametersOptions: null,
  calculations: null,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getParametersOptions.fulfilled, (state, action) => {
      state.parametersOptions = action.payload
    })
    .addCase(calculate.fulfilled, (state, action) => {
      state.calculations = action.payload
    })
    .addCase(resetCalculations, (state) => {
      state.calculations = null
    })
})

export { reducer }
