import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import ActionType from './common'

const getParametersOptions = createAsyncThunk(
  ActionType.GET_PARAMETERS_OPTIONS,
  async (_, { extra: { services } }) => {
    const { data } = await services.calculations.getParametersOptions()

    return data
  }
)

const resetCalculations = createAction(ActionType.RESET_CALCULATIONS)

const calculate = createAsyncThunk(
  ActionType.CALCULATE,
  async (payload, { extra: { services } }) => {
    const response = await services.calculations.calculate(
      payload.data,
      payload.dispatch,
      resetCalculations
    )

    return response.data
  }
)

export { getParametersOptions, resetCalculations, calculate }
