import { get, post } from 'helpers/http.helper'

const Calculations = (() => {
  const getParametersOptions = () =>
    get('rodms/calculations/parameters/options')

  const calculate = (payload, dispatch, action) =>
    post('rodms/calculations', payload, {}, dispatch, action)

  return {
    getParametersOptions,
    calculate,
  }
})()

export default Calculations
